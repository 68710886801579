.mobile-container {
  width: 100%;
}

.nav-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(34, 34, 34);
  width: 100%;
  height: 12vh;
  box-sizing: border-box;
  padding: 0 3vw;
}

.mobile-name {
  font-size: 1.5rem;
  max-width: 5rem;
  letter-spacing: 0.2rem;
  color: rgb(145, 28, 28);
  height: auto;
}

.mobile-photo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
}

.mono-image-mobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.mono-image-mobile.visible {
  opacity: 1;
}

.mono-image-mobile.hidden {
  opacity: 0;
}

.logo-mobile {
  position: absolute;
  bottom: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  max-width: 20vh;
  min-width: 20vh;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.9));
}
