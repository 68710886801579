html {
  scroll-behavior: smooth;
}

.desktop-container {
  width: 100%;
  height: 100%;
}

.nav-desktop {
  position: fixed;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(34, 34, 34);
  width: 100%;
  height: 10vh; /* Escalable según la altura de la pantalla */
  box-sizing: border-box;
  padding: 0 2vw; /* Añadir padding en relación al ancho de la pantalla */
  z-index: 1000;
}

.logo-desktop {
  width: 5rem;
  max-width: 5rem;
  height: auto;
  margin-top: 1vh;
  padding: 0 1vw;
}

.desktop-name {
  margin: 0;
  padding: 0;
  font-size: 2.6rem; /* Tamaño escalable de la fuente */
  color: rgb(145, 28, 28);
  letter-spacing: 0.5vw; /* Espaciado de letras relativo al ancho */
  cursor: default;
  padding-left: 22vw;
}

.desktop-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 2.7vw; /* Espacio entre elementos de la lista */
  padding: 0 1vw;
}

.desktop-menu li {
  font-size: 1.1rem; /* Tamaño de fuente escalable */
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: color 0.4s ease;
  padding-bottom: 0.2rem;
  position: relative;
}

.desktop-menu li::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px; /* Grosor de la línea */
  background-color: rgb(182, 23, 23); /* Color de la línea subrayada */
  transition: width 0.5s ease-in-out; /* Animación de despliegue */
}

.desktop-menu li:hover::after {
  width: 100%; /* Despliega la línea subrayada */
}

.desktop-menu li:hover {
  color: rgb(182, 23, 23);
}

.desktop-menu a {
  color: inherit; /* Evita el color azul y morado predeterminado de los enlaces */
  text-decoration: none; /* Quita el subrayado de los enlaces */
  transition: color 0.3s ease;
}

.desktop-menu a:hover {
  color: rgb(145, 28, 28); /* Color al pasar el ratón por encima */
}

.desktop-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90vh; /* Escalable con la altura de la pantalla */
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  padding-top: 10vh;
}

.left-container,
.right-container {
  flex: 1;
  display: flex;
  height: 100%;
}

.logo-desktop-body {
  max-width: 20vw; /* Tamaño del logo relativo al ancho */
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.9));
}

.left-container {
  background-color: black;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.mono-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: opacity 1.5s ease-in-out;
  opacity: 0;
}

.mono-image.visible {
  opacity: 1;
}

.mono-image.hidden {
  opacity: 0;
}

.desktop-filter {
  /*  background-color: #9e6b6b4d; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
  margin-bottom: 8vh; /* Espacio inferior escalable */
  padding: 0 -10vw; /* Padding adaptable según el ancho de la pantalla */
}

.right-container h1 {
  font-size: 2rem; /* Tamaño de fuente escalable */
  margin-bottom: 1vh; /* Espacio inferior relativo a la altura */
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: 0.1rem; /* Espaciado de letras escalable */
  margin-left: 0;
  padding-left: 6.8vw;
}

.right-container p {
  font-size: 1.1rem; /* Tamaño de fuente escalable */
  line-height: 2; /* Espaciado entre líneas */
  max-width: 25vw; /* Ancho máximo escalable */
  margin: 0 auto; /* Centrado automático */
  padding-left: 7vw;
}

.desktop-footpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 10vh;
  background-color: black;
  z-index: 3;
}
